import { Page } from '~/components/Page'
import './wallet.css'

export const WalletPage = () => {
    return (
        <Page title='Wallet' header='Wallet' className='wallet-page'>
            <header className='wallet-page__header'></header>
            <section className='wallet-page__section'>
                <div className='wallet-page__caption'>
                    <h1 className='wallet-page__h1'>BR1: INFINITE IS A WEB3 GAME</h1>
                    <h3 className='wallet-page__h3'>TAKE FULL CONTROL OF YOUR GAME ITEMS.</h3>
                    <h4 className='wallet-page__h4 wallet-page__subcaption'>
                        Set up a secure game wallet to unlock the full power of BR1: INFINITE.
                        <br />
                        Own your items, trade them, or sell them—it's all up to you!
                    </h4>
                </div>
                <div className='wallet-page__choices'>
                    <a href='#newbie' className='wallet-page__choice wallet-page__choice--newbie'>
                        <div>
                            <h5 className='wallet-page__h5'>NEW TO THIS? NO WORRIES.</h5>
                            <h2 className='wallet-page__h2'>LET’S GET YOU STARTED</h2>
                        </div>
                        <div className='wallet-page__choice-icon wallet-page__choice-icon--me'>POWERED BY</div>
                    </a>
                    <a href='#veteran' className='wallet-page__choice wallet-page__choice--veteran'>
                        <div>
                            <h5 className='wallet-page__h5'>I’M A WEB3 VETERAN</h5>
                            <h2 className='wallet-page__h2'>CONNECT MY WALLET</h2>
                        </div>
                        <div className='wallet-page__choice-icon wallet-page__choice-icon--wallet-group'></div>
                    </a>
                    {/* <div className='wallet-page__choice wallet-page__choice--new'>
                        <p>
                            <h3>NEW TO THIS? NO WORRIES.</h3>
                            <h2>LET’S GET YOU STARTED</h2>
                        </p>
                        <div className='wallet-page__me_powered'></div>
                    </div>
                    <div className='wallet-page__choice wallet-page__choice--veteran'>
                        <p>
                            <h3>I’M A WEB3 VETERAN</h3>
                            <h2>CONNECT MY WALLET</h2>
                        </p>
                        <div
                            className='relative h-full w-[15%] bg-black/50 bg-contain bg-no-repeat bg-center bg-origin-content p-3'
                            style={{
                                backgroundImage: `url('/media/assets/icons/wallet_grouped.png')`,
                            }}
                        ></div>
                    </div> */}
                </div>
            </section>
        </Page>
    )
}
